import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const config = {
  position: "top-right",
  autoClose: 3500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const Toaster = (msg, type, onClose) => {
  switch (type) {
    case "success":
      toast.success(msg, { onClose: onClose, ...config });
      break;
    case "error":
      toast.error(msg, { onClose: onClose, ...config });
      break;
    default:
      break;
  }
};

const showToaster = (isShowToaster = true, message, type) => {
  isShowToaster && Toaster(message, type);
};

export const handleResponseWithToaster = ({
  response,
  isShowToaster = true,
  onAfterSuccess,
  onAfterFailure,
}) => {
  const { data = {}, meta = {} } = response;

  let { message } = meta;
  let { error = "" } = data;

  if (!isEmpty(error)) {
    showToaster(isShowToaster, error, "error");
    onAfterFailure && onAfterFailure({ response, message });
  } else {
    showToaster(isShowToaster, message, "success");
    onAfterSuccess && onAfterSuccess({ response, message });
  }
};

export default Toaster;
