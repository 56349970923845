import {
  addItem,
  clearItem,
  getToken,
  getUser,
  isValidToken,
} from "lib/helpers/localStorage";
import { PROPERTY } from "components/Routes/Constants";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  register,
  login,
  loginApp,
  forgotPassword,
  updatePassword,
} from "state/Auth/Epic";
import React, { useState } from "react";
import Toaster, {
  handleResponseWithToaster,
} from "components/global-components/Components/Toaster";

export const AuthContext = React.createContext();

const AuthProvider = ({
  register,
  login,
  loginApp,
  forgotPassword,
  history,
  updatePassword,
  ...props
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(isValidToken());
  const [user, setUser] = useState(getUser());
  const [token, setToken] = useState(getToken());

  const handleResponse = (response, turnOffSubmitting) => {
    handleResponseWithToaster({
      isShowToaster: true,
      response,
      onAfterSuccess: turnOffSubmitting(),
      onAfterFailure: turnOffSubmitting(),
    });
  };
  const onHandleRegister = (values, turnOffSubmitting, turnOnSubmitting) => {
    turnOnSubmitting();
    register({ user: values })
      .then((res) => {
        const { data = {} } = res;
        const { error = "" } = data;
        if (isEmpty(error) && !isEmpty(data)) {
          tokenAuth(data);
          handleResponse(res, turnOffSubmitting);
          history.push(`${PROPERTY}/create#step1`);
        } else {
          handleResponse(res, turnOffSubmitting);
        }
      })
      .catch((err) => {
        handleResponse(err, turnOffSubmitting);
      });
  };

  const onHandleLoginApp = (values, turnOffSubmitting) => {
    loginApp({ user: values })
      .then((res) => {
        const { data = {} } = res;
        const { error = "" } = data;
        if (isEmpty(error) && !isEmpty(data)) {
          tokenAuth(data);
          handleResponse(res, turnOffSubmitting);
          history.push(`${PROPERTY}/create#step1`);
        } else {
          handleResponse(res, turnOffSubmitting);
        }
      })
      .catch((err) => {
        handleResponse(err, turnOffSubmitting);
      });
  };

  const onHandleLogin = (values, turnOffSubmitting, turnOnSubmitting) => {
    turnOnSubmitting();

    login({ user: values })
      .then((res) => {
        const { data = {} } = res;
        const { error = "" } = data;
        if (isEmpty(error) && !isEmpty(data)) {
          tokenAuth(data);
          handleResponse(res, turnOffSubmitting);

          history.push(`${PROPERTY}/create#step1`);
        } else {
          handleResponse(res, turnOffSubmitting);
        }
      })
      .catch((err) => {
        handleResponse(err, turnOffSubmitting);
      });
  };

  /**
   * For 3rd-party Authentication [e.g. Autho0, firebase, AWS etc]
   *
   */
  const tokenAuth = (data) => {
    const { userData = null } = data;
    setUser(userData);
    addItem("token", data);
    setIsLoggedIn(true);
  };

  const forgetPass = (values, turnOffSubmitting, turnOnSubmitting) => {
    turnOnSubmitting();
    forgotPassword(values).then((res) => {
      handleResponse(res, turnOffSubmitting);
    });
  };

  const changePass = (values, turnOffSubmitting, turnOnSubmitting) => {
    turnOnSubmitting();
    updatePassword(values).then((response) => {
      turnOffSubmitting();

      handleResponseWithToaster({
        isShowToaster: true,
        response,
        onAfterSuccess: () => {
          history.push(`/login`);
        },
      });
    });
  };

  const logOut = () => {
    setUser(null);
    setToken(null);
    clearItem("token");
    setIsLoggedIn(false);
    Toaster("Log out Successfully ", "success");
    history.push("/");
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        logOut,
        forgetPass,
        changePass,
        tokenAuth,
        user,
        token,
        setIsLoggedIn,
        onHandleRegister,
        onHandleLogin,
        onHandleLoginApp,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  register: (params) => dispatch(register(params)),
  login: (params) => dispatch(login(params)),
  loginApp: (params) => dispatch(loginApp(params)),
  forgotPassword: (params) => dispatch(forgotPassword(params)),
  updatePassword: (params) => dispatch(updatePassword(params)),
});
export default connect(null, mapDispatchToProps)(withRouter(AuthProvider));
