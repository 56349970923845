import API from "../../lib/api/auth";
import {
  Begin,
  Failure,
  Success,
  UpdateBegin,
  UpdateSuccess,
  UpdateFailure,
} from "./Actions";
import BaseEpic from "../BaseEpic";

const baseEpicObject = new BaseEpic();

const BASE = "AUTH";

const { update, get } = baseEpicObject.CreateModuleEpics(BASE, API);

const login = (params) => async (dispatch) =>
  baseEpicObject.makeCall(
    API.login,
    { params },
    Begin,
    Success,
    Failure,
    dispatch
  );

const register = (params) => async (dispatch) =>
  baseEpicObject.makeCall(
    API.register,
    { params },
    Begin,
    Success,
    Failure,
    dispatch
  );

const checkEmail = (params) => async (dispatch) =>
  baseEpicObject.makeCall(
    API.checkEmail,
    { params },
    Begin,
    Success,
    Failure,
    dispatch
  );

const loginApp = (params) => async (dispatch) =>
  baseEpicObject.makeCall(
    API.loginApp,
    { params },
    Begin,
    Success,
    Failure,
    dispatch
  );

const forgotPassword = (params) => async (dispatch) =>
  baseEpicObject.makeCall(
    API.forgotPassword,
    { params },
    Begin,
    Success,
    Failure,
    dispatch
  );

const updatePassword = (params) => async (dispatch) =>
  baseEpicObject.makeCall(
    API.updatePassword,
    { params },
    Begin,
    Success,
    Failure,
    dispatch
  );

const updateImages = (id, params) => async (dispatch) => {
  return baseEpicObject.makeCall(
    API.updateWithFormData,
    { id, params },
    UpdateBegin,
    UpdateSuccess,
    UpdateFailure,
    dispatch
  );
};

export {
  get,
  login,
  register,
  loginApp,
  forgotPassword,
  updatePassword,
  update,
  updateImages,
  checkEmail,
};
