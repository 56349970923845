import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import AppMain from "components/Routes/index";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./Boot/auth/AuthProvider";

import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "./assets/css/index.scss";
import ScrollToTop from "./components/ScrollToTop";
import store from "./config/configureStore";
import theme from "./themes/default.theme";
import { LoadScript } from "@react-google-maps/api";

const Root = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <ScrollToTop>
              <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              >
                <AppMain />
              </LoadScript>
              <ToastContainer />
            </ScrollToTop>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default Root;

ReactDOM.render(<Root />, document.getElementById("realdeal"));
