import { CreateModuleActions } from "./../BaseActions";
const BASE = "AUTH";

export const {
  BEGIN,
  SUCCESS,
  FAILURE,
  UPDATE_BEGIN,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  Begin,
  Success,
  Failure,
  UpdateBegin,
  UpdateSuccess,
  UpdateFailure,
} = CreateModuleActions(BASE);
