import filter from "lodash/filter";
import keys from "lodash/keys";

import {
  GetSecured,
  PostSecured,
  PutSecured,
  DeleteSecured,
} from "./../client/REST";

import { isObject } from "../helpers/helpers";

export const makeQueryString = (params) => {
  var queryString = "";
  if (params !== undefined) {
    queryString = filter(keys(params), (key) => params[key] != null)
      .map((key) => {
        if (isObject(params[key])) {
          let nestedObj = params[key];
          return filter(keys(nestedObj), (key1) => nestedObj[key1] != null)
            .map((key1) => {
              return (
                encodeURIComponent(key1) +
                "=" +
                encodeURIComponent(nestedObj[key1])
              );
            })
            .join("&");
        } else {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        }
      })
      .join("&");
  }

  return queryString;
};

class API {
  constructor({ uri, delete_uri, search_uri, multiple_ids, object_key }) {
    this.uri = uri;
    this.delete_uri = delete_uri;
    this.search_uri = search_uri;
    this.multiple_ids = multiple_ids;
    this.object_key = object_key;
  }

  index = async (queryParams) => {
    return GetSecured(
      `/${this.uri}?${makeQueryString({
        ...queryParams,
      })}`
    );
  };

  get = ({ id, queryParams = {} }) => {
    return GetSecured(
      `/${this.uri}/id/${id}?${makeQueryString({
        ...queryParams,
      })}`
    );
  };

  create = (params) =>
    PostSecured(`/${this.uri}`, { [this.object_key]: params });

  update = ({ id, params, locale }) => {
    return PutSecured(`/${this.uri}/id/${id}`, { [this.object_key]: params });
  };

  remove = ({ selected_ids }) => {
    return DeleteSecured(`/${this.uri}/id/${selected_ids}`);
  };

  removeMultiple = ({ selected_ids }) =>
    DeleteSecured(
      `/${this.uri}/${this.delete_uri}?${this.multiple_ids}=${selected_ids}`
    );

  search = (queryParams) => {
    return GetSecured(
      `/${this.uri}/search?${makeQueryString({
        ...queryParams,
      })}`
    );
  };

  getFromQueryParams = (queryParams) =>
    GetSecured(
      `/${this.uri}/?${makeQueryString({
        ...queryParams,
      })}`
    );

  getFromUri = (uri, queryParams) => {
    return GetSecured(
      `/${this.uri}/${uri}?${makeQueryString({
        ...queryParams,
      })}`
    );
  };
}

export default API;
