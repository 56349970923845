import { CreateModuleReducers } from "./../BaseReducer";
import { APPROVE_LISTING, USER_LISTING } from "./Actions";

const approveReducer = CreateModuleReducers(APPROVE_LISTING);
const userReducer = CreateModuleReducers(USER_LISTING);

export {
    approveReducer,
    userReducer
};
