import { SUCCESS, FAILURE, BEGIN } from "./Actions";

import { begin } from "./../BaseReducer";

// reducers
const authReducer = (
  state = {
    context: {},
  },
  action
) => {
  switch (action.type) {
    case BEGIN:
      return begin(state);
    case SUCCESS:
      return {
        ...state,
        context: action.data,
      };
    case FAILURE:
      return { ...state, error: action };

    default:
      return state;
  }
};

export default authReducer;
