import { CreateModuleActions } from "../BaseActions";
export const APPROVE_LISTING = "APPROVE_LISTING";
export const USER_LISTING = "USER_LISTING";

export const {
  ListBegin:   ApproveListingsBegin,
  ListSuccess: ApproveListingsSuccess,
  ListFailure: ApproveListingsFailure,
  UpdateBegin:   ApproveUpdateBegin,
  UpdateSuccess: ApproveUpdateSuccess,
  UpdateFailure: ApproveUpdateFailure,
  MarkSelected:ApproveMarkSelected,
  DeleteSelectedSuccess: ApproveDeleteSelectedSuccess
} = CreateModuleActions(APPROVE_LISTING);

export const {
  ListBegin:   UserListingsBegin,
  ListSuccess: UserListingsSuccess,
  ListFailure: UserListingsFailure,
} = CreateModuleActions(USER_LISTING);
