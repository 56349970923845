import includes from "lodash/includes";
const isLive = true;
const domainName = isLive
  ? "https://directrent.ai/api"
  : "http://localhost:5009/api";
const getDomainUrl = (subdomain) => domainName;

export const localDevServerUrl = process.env.REACT_APP_LOCAL_DEV_SERVER_URL;
export const devHostedServerUrl = getDomainUrl("api");

export const isLocalHosted = includes(window.location.hostname, domainName);

export const isRunLocalHosted = includes(
  process.env.REACT_APP_LOCAL_DEV_SERVER_URL,
  domainName
);

export const isDevHosted = includes(
  window.location.hostname,
  getDomainUrl(domainName)
);

export const isStagingHosted = includes(
  window.location.hostname,
  getDomainUrl("directrent")
);

export default {
  isLocalHosted,
  isRunLocalHosted,
  isStagingHosted,
  isDevHosted,
};
