export const addItem = (key, value = "") => {
  if (key) localStorage.setItem(key, JSON.stringify(value));
};

export const clearItem = (key) => {
  localStorage.removeItem(key);
};

export const getTokenItem = () => {
  return localStorage.getItem("token");
};

export const getToken = () => {
  const data = getTokenItem();
  if (data) {
    return JSON.parse(localStorage.getItem("token")).token;
  }
  return null;
};

export const clearToken = () => {
  clearItem("token");
};

export const addToken = (tokenObject) => {
  addItem("token", tokenObject);
};

export const getUser = () => {
  const token = getTokenItem();

  if (token) {
    return JSON.parse(token).userData;
  }
  return null;
};

export const isValidToken = () => {
  const token = getTokenItem();

  // JWT decode & check token validity & expiration.
  // https://www.npmjs.com/package/jwt-decode
  if (token) return true;
  return false;
};
