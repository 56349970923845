import { Post, Put, PutSecured, PUTSecuredFormData } from "../client/REST";
import API from "./BaseAPI";
import { uri as image_uri } from "./images";

const uri = "users";
const login_uri = "login";
const login_app_uri = "loginApp";
const register_uri = "register";
const delete_uri = "delete_users";
const forgot_uri = "forgot_password";
const change_uri = "change_password";
const search_uri = "search";
const multiple_ids = "user_ids";
const object_key = "user";
const uri_profile_photo = "profile_photo";
const check_email = "check-email";

const BaseAPI = new API({
  uri,
  delete_uri,
  search_uri,
  multiple_ids,
  object_key,
});

export default {
  ...BaseAPI,
  login: ({ params }) => Post(`/${login_uri}`, params),
  loginApp: ({ params }) => {
    return Post(`/${login_app_uri}`, params);
  },
  register: ({ params }) => Post(`/${register_uri}`, params),
  checkEmail: ({ params }) => Post(`/${check_email}`, { user: params }),
  updatePassword: ({ params }) => Post(`/${change_uri}`, { user: params }),
  forgotPassword: ({ params }) => Post(`/${forgot_uri}`, { user: params }),
  updateWithFormData: ({ params }) => {
    return PUTSecuredFormData(`/${image_uri}/${uri_profile_photo}`, {
      ...params,
    });
  },
  updateUserAccount: ({ id, params, }) => {
    return PutSecured(`/${uri}/active/${id}`, {
      "user" : params,
    });
  },
};
