import { AuthContext } from "Boot/auth/AuthProvider";
import React, { Fragment, Suspense, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { protectedRoutes } from "./PrivateRoutes/PrivateRoute";
import { publicRoutes } from "./PublicRoutes/PublicRoutes";
import NotFound from "Pages/404/404";

const InitialLoading = () => (
  <div className="preloader" id="preloader">
    <div className="preloader-inner">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  </div>
);

const renderPublicRoutes = () => {
  return publicRoutes.map((Obj, index) => (
    <Route exact path={Obj.path} component={Obj.component} key={index} />
  ));
};

const renderProtectedRoutes = () => {
  return protectedRoutes.map((Obj, index) => (
    <Route
      path={Obj.path}
      component={Obj.component}
      key={index}
      exact={Obj.exact}
    />
  ));
};

const AppMain = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Fragment>
      <Suspense fallback={<InitialLoading />}>
        <Switch>
          {renderPublicRoutes()}
          {isLoggedIn && renderProtectedRoutes()}
          <Route exact component={() => <NotFound />} />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default AppMain;
