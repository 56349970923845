export const HOME = "/";
export const PROPERTY = "/property";
export const LISTING = "/listing";
export const TENANT = "/tenants";
export const LANDLORD = "/landlords";
export const DASHBOARD = "/dashboard";
export const ADMIN_DASHBOARD = "/admin-dashboard";
export const USER_DETAIL = "/user-detail";
export const LANDLORD_SERVICE = "/landlord-service";
export const PROPERTY_SERVICE = "/property-service";
export const MY_LISTING = "/my-listing";
export const MESSAGE_LANDLORD = "/messagelandlord";
export const REQUEST_VIEWING = "/requestviewing";
export const DIRECT_TEXT = "/directext";
export const RENTAL_PROCESS = "/rentprocess";
export const SINGLE_LIST = "/listing/:Id";
export const LANDLORD_DASHBOARD = "landlord-dashboard";
export const TENANT_REFERENCING = "/tenant-referencing";
export const TENANT_CREATION = "/tenant-creation";
export const SIGNUP = "/signup";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const CHANGE_PASSWORD = "/change-password/:tokenId";
export const INVITE = "/invite";
export const STOP_ADVERT = "/stop-advert";
export const UPLOAD_FILES = "/upload-files";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_OF_SERVICES = "/terms-of-services";
