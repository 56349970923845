import map from "lodash/map";
import compact from "lodash/compact";
import filter from "lodash/filter";
import size from "lodash/size";
import pullAll from "lodash/pullAll";

export const RandomNumber = Math.ceil(Math.random() * 1000);
export const getFromQueryString = (param) => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  return params.get(param);
};

export const queryStringToObject = () => {
  let search = window.location.search;
  return Object.fromEntries(new URLSearchParams(search));
};

export const getQueryStringKeyVal = (key) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
};

export const decimalLimitCheck = (number, limit) => {
  if (number) {
    var decPart = (number + "").split(".")[1];
    return decPart ? decPart.length <= limit : true;
  }
  return false;
};

export const pickByKey = (array, key) => {
  return compact(
    map(array, ({ [key]: renamed }) => ({
      value: renamed,
    }))
  );
};

export const pickUndestroyed = (list) => {
  return filter(list, ({ _destroy }) => _destroy !== true);
};

export const isFile = (obj) => {
  return obj instanceof File;
};

export const getImagesFromDetailAttributes = (detailAttributes) => {
  return filter(
    map(detailAttributes, ({ image, id }) => ({
      id,
      image: isFile(image) ? image : undefined,
    })),
    ({ image }) => (image ? true : false)
  );
};

export const getInitials = (text = "") =>
  text
    .match(/(^\S\S?|\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();

export const isCreateMode = () => {
  const { pathname } = window.location;

  const mode = !(pathname === "/")
    ? pathname.split("/") && pathname.split("/")["2"]
    : "";

  return mode === "create";
};

export const removeTrailingSlash = (str) => str.replace(/\/$/, "");

export const isNotNullObject = (obj) => {
  const values = Object.values(obj);
  return size(filter(values, (item) => item === null)) === 0;
};

export const replaceNullValues = (obj, defaultValue) => {
  const values = Object.values(obj);
  return map(values, (item) => {
    if (item === null) {
      return defaultValue;
    }
    return item;
  });
};

export const extractShortPathNameString = (pathname) => {
  let pathNameChunks = pathname.split("/");
  const ignoreList = ["create"];

  pathNameChunks = compact(pathNameChunks);
  pullAll(pathNameChunks, ignoreList);

  if (size(pathNameChunks) >= 3) {
    pathNameChunks.pop();
  }

  return pathNameChunks.join("/");
};

export const isObject = (objVal) =>
  objVal && typeof objVal === "object" && objVal.constructor === Object;

export const debounce =(func, delay) => {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(func, delay);
  };
}
