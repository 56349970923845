import { combineReducers } from "redux";
import listing, { myReducer } from "state/Listing/Reducers";
import {approveReducer, userReducer} from "state/AdminDashboard/Reducers"

// import auth from '../state/auth/Reducers';
import auth from "../state/Auth/Reducers";

export default combineReducers({
  Auth: auth,
  Listing: listing,
  MyListing: myReducer,
  Approve: approveReducer,
  User: userReducer,
});
