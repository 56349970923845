import { lazy } from "react";
import {
  HOME,
  LISTING,
  LOGIN,
  SIGNUP,
  TENANT,
  LANDLORD,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  SINGLE_LIST,
  PRIVACY_POLICY,
  TERMS_OF_SERVICES,
} from "../Constants";

export const publicRoutes = [
  {
    path: HOME,
    component: lazy(() => import("../../home-v3")),
    exact: true,
  },
  {
    path: LISTING,
    component: lazy(() => import("../../../Pages/Listing")),
    exact: true,
  },
  {
    path: SINGLE_LIST,
    component: lazy(() =>
      import("../../global-components/Components/SingleProperty.js")
    ),
    exact: true,
  },
  {
    path: SIGNUP,
    component: lazy(() => import("../../../Pages/User/SignUp")),
    exact: true,
  },
  {
    path: LOGIN,
    component: lazy(() => import("../../../Pages/User/SignIn")),
    exact: true,
  },
  {
    path: FORGOT_PASSWORD,
    component: lazy(() => import("../../../Pages/User/ForgotPassword")),
    exact: true,
  },
  {
    path: CHANGE_PASSWORD,
    component: lazy(() => import("../../../Pages/User/ChangePassword")),
    exact: true,
  },
  {
    path: TENANT,
    component: lazy(() => import("../../../Pages/Tenants")),
    exact: true,
  },
  {
    path: LANDLORD,
    component: lazy(() => import("../../../Pages/Landlords")),
    exact: true,
  },
  {
    path: PRIVACY_POLICY,
    component: lazy(() => import("../../../Pages/PrivacyPolicy")),
  },
  {
    path: TERMS_OF_SERVICES,
    component: lazy(() => import("../../../Pages/TermsOfServices")),
  },
  {
    path: "/googlec787e976e8df8de3",
    component: lazy(() =>
      import("../../../Pages/GoogleVerification/verification")
    ),
  },
];
