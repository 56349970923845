import React from "react";
import Image from "components/Image";
import Heading from "components/Heading";
import NotFoundWrapper, { ContentWrapper } from "./404.style";
import { Button } from "reactstrap";
import Image404 from "assets/img/404.svg";

export default ({ staticContext = {} }) => {
  setTimeout(() => {
    window.location.href = "/";
  }, 2000);
  staticContext.status = 404;
  return (
    <NotFoundWrapper>
      <ContentWrapper>
        <Image src={Image404} alt="404" />
        <Heading as="h2" content="Something Went Wrong" />
        <Button
          className="ErrorBackBtn"
          onClick={() => {
            window.location.href = "/";
          }}
          content="Go Back"
        >
          <>Go Back</>
        </Button>
      </ContentWrapper>
    </NotFoundWrapper>
  );
};
