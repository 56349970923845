import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import React, { lazy } from "react";
import { Route } from "react-router-dom";
import {
  PROPERTY,
  DASHBOARD,
  LANDLORD_SERVICE,
  PROPERTY_SERVICE,
  MY_LISTING,
  MESSAGE_LANDLORD,
  REQUEST_VIEWING,
  DIRECT_TEXT,
  RENTAL_PROCESS,
  SINGLE_LIST,
  LANDLORD_DASHBOARD,
  TENANT_REFERENCING,
  TENANT_CREATION,
  INVITE,
  STOP_ADVERT,
  USER_DETAIL,
  ADMIN_DASHBOARD
} from "../Constants";

export const protectedRoutes = [
  {
    path: PROPERTY,
    component: lazy(() => import("../../../Pages/AddListing/index")),
    exact: false,
  },
  {
    path: DASHBOARD,
    component: lazy(() => import("../../../Pages/Dashboard")),
    exact: false,
  },
  {
    path: ADMIN_DASHBOARD,
    component: lazy(() => import("../../../Pages/AdminDashboard")),
    exact: false,
  },
  {
    path: LANDLORD_SERVICE,
    component: lazy(() => import("../../../Pages/LandlordServices")),
    exact: false,
  },
  {
    path: PROPERTY_SERVICE,
    component: lazy(() => import("../../../Pages/PropertyServices")),
    exact: false,
  },
  {
    path: MY_LISTING,
    component: lazy(() => import("../../../Pages/MyListing")),
    exact: true,
  },
  {
    path: MESSAGE_LANDLORD,
    component: lazy(() => import("../../../Pages/LandlordMessaging")),
    exact: true,
  },
  {
    path: REQUEST_VIEWING,
    component: lazy(() => import("../../../Pages/RequestViewing")),
    exact: true,
  },
  {
    path: DIRECT_TEXT,
    component: lazy(() => import("../../../Pages/Message")),
    exact: true,
  },
  {
    path: RENTAL_PROCESS,
    component: lazy(() => import("../../../Pages/RentProcess")),
    exact: true,
  },
  {
    path: RENTAL_PROCESS,
    component: lazy(() => import("../../../Pages/RentProcess")),
    exact: true,
  },
  {
    path: LANDLORD_DASHBOARD,
    component: lazy(() => import("../../../Pages/LandlordDashboard")),
    exact: false,
  },
  {
    path: TENANT_REFERENCING,
    component: lazy(() => import("../../../Pages/TenantReferencing")),
    exact: true,
  },
  {
    path: INVITE,
    component: lazy(() => import("../../../Pages/Invite")),
    exact: true,
  },
  {
    path: STOP_ADVERT,
    component: lazy(() => import("../../../Pages/StopAdvert")),
    exact: true,
  },
  {
    path: USER_DETAIL,
    component: lazy(() => import("../../../Pages/UserDetails")),
  },
  {
    path: TENANT_CREATION,
    component: lazy(() => import("../../../Pages/TenantCreation")),
    exact: true,
  },
];

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
