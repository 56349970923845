import { PUTSecuredFormData } from "../client/REST";

export const uri = "images";
export const uri_image_gallery = "image_gallery";

export default {
  update: ({ params }) => {
    return PUTSecuredFormData(`/${uri}/${uri_image_gallery}`, { ...params });
  },
};
